import React, { useState, useEffect, useCallback } from 'react';
import { MessageSquare, X, Send, Loader, Trash2 } from 'lucide-react';
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  organization: process.env.REACT_APP_OPENAI_ORG_ID,
  dangerouslyAllowBrowser: true // Only for development
});

const MESSAGE_LIMIT = 10; // Maximum number of messages per 24 hours
const TIME_WINDOW = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const COMPANY_INFO = {
  services: [
    {
        name: "Joruthe LLC",
        description: "Joruthe LLC began as a small passionate endeavor, founded in April 2020 to bring quality and reliability into the home repair and renovation industry.Joseph saw an opportunity to blend technical expertise with a personal touch, transforming houses into homes that not only look beautiful but feel right. Over the years, we've grown from a one-person operation into a thriving team of skilled professionals, each of us dedicated to making your home renovation dreams come true. Our journey has been built on a foundation of trust, craftsmanship, and a deep understanding of our clients needs. We believe in not just meeting expectations but exceeding them, ensuring every project, big or small, is completed with the utmost care and precision. At Joruthe LLC, we're more than just builders, we're creators of comfort and confidence, transforming spaces into personalized sanctuaries where life's best moments happen."
      },
    {
      name: "Plumbing",
      description: "Expert plumbing services including leak repairs, installations, and maintenance."
    },
    {
      name: "Electrical",
      description: "Comprehensive electrical solutions for residential and commercial properties."
    },
    {
      name: "Carpentry",
      description: "Custom woodworking, repairs, and installations for all your carpentry needs."
    },
    {
      name: "Renovation",
      description: "Full-scale home renovations and remodeling projects."
    },
    {
      name: "Lawn Care",
      description: "Professional lawn maintenance, landscaping, and garden design services."
    },
    {
      name: "Fencing",
      description: "Installation and repair of various fencing types for enhanced property security and aesthetics."
    },
    {
        name: "Owner",
         description: "Founded in April 2020 by Joseph Oladiran, Joruthe LLC began as a small, passionate endeavor to bring quality and reliability into the home repair and renovation industry. With a background in building technology and civil engineering, Joseph saw an opportunity to blend technical expertise with a personal touch, transforming houses into homes that not only look beautiful but feel right."      
    },
  ],
  contactInfo: {
    phone: "+1 (765) 631 5540",
    email: "joseph@joruthe.com",
    address: "2680 E Main Street Plainfield, IN 46168"
  },
  businessHours: "7 days a week, 7:00 AM to 9:00 PM"
};

const systemMessage = {
  role: "system",
  content: `You are Ruthie, a customer service chatbot for Joruthe LLC, a home repair and renovation company. 
  Provide friendly and informative responses based solely on the following company information:
  
  ${JSON.stringify(COMPANY_INFO)}
  
  Only answer questions related to the services, contact information, and business hours provided above. 
  If asked about anything not covered in this information, politely state that you don't have that information 
  and offer to connect the customer with a human representative. Always be polite and professional.`
};

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [messageCount, setMessageCount] = useState(0);
  const [lastMessageTime, setLastMessageTime] = useState(Date.now());

  const initialMessage = { text: "Hi, I'm Ruthie, your virtual assistant for Joruthe LLC. How may I help you today?", isBot: true };

  useEffect(() => {
    const storedMessages = localStorage.getItem('ruthieChatMessages');
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    } else {
      setMessages([initialMessage]);
    }

    const storedCount = localStorage.getItem('ruthieChatCount');
    const storedTime = localStorage.getItem('ruthieChatLastTime');
    if (storedCount && storedTime) {
      const timePassed = Date.now() - parseInt(storedTime);
      if (timePassed < TIME_WINDOW) {
        setMessageCount(parseInt(storedCount));
        setLastMessageTime(parseInt(storedTime));
      } else {
        localStorage.setItem('ruthieChatCount', '0');
        localStorage.setItem('ruthieChatLastTime', Date.now().toString());
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('ruthieChatMessages', JSON.stringify(messages));
  }, [messages]);

  const toggleChat = () => setIsOpen(!isOpen);

  const handleInputChange = (e) => setInputMessage(e.target.value);

  const clearChat = useCallback(() => {
    setMessages([initialMessage]);
    setMessageCount(0);
    setLastMessageTime(Date.now());
    localStorage.removeItem('ruthieChatMessages');
    localStorage.setItem('ruthieChatCount', '0');
    localStorage.setItem('ruthieChatLastTime', Date.now().toString());
  }, []);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    if (messageCount >= MESSAGE_LIMIT) {
      const timeLeft = TIME_WINDOW - (Date.now() - lastMessageTime);
      const hoursLeft = Math.ceil(timeLeft / (1000 * 60 * 60));
      setError(`Message limit reached. You can send more messages in ${hoursLeft} hours.`);
      return;
    }

    const newMessage = { text: inputMessage, isBot: false };
    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    setIsLoading(true);
    setError(null);

    const newCount = messageCount + 1;
    setMessageCount(newCount);
    setLastMessageTime(Date.now());
    localStorage.setItem('ruthieChatCount', newCount.toString());
    localStorage.setItem('ruthieChatLastTime', Date.now().toString());

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          systemMessage,
          ...messages.map(msg => ({
            role: msg.isBot ? "assistant" : "user",
            content: msg.text
          })),
          { role: "user", content: inputMessage }
        ],
        temperature: 0.7,
        max_tokens: 150
      });

      const botResponse = response.choices[0].message.content.trim();
      
      const verificationResponse = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          systemMessage,
          { role: "user", content: `Does this response: "${botResponse}" contain any information not present in the provided company information? Answer only Yes or No.` }
        ],
        temperature: 0,
        max_tokens: 5
      });

      if (verificationResponse.choices[0].message.content.toLowerCase().includes('yes')) {
        setMessages(prev => [...prev, { text: "I apologize, but I don't have accurate information to answer that question. Would you like me to connect you with a human representative?", isBot: true }]);
      } else {
        setMessages(prev => [...prev, { text: botResponse, isBot: true }]);
      }
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      if (error.response) {
        console.error('API Error Response:', error.response.data);
        console.error('API Error Status:', error.response.status);
        setError(`API Error: ${error.response.status} - ${error.response.data.error?.message || 'Unknown error'}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('No response received from the server. Please check your internet connection.');
      } else {
        console.error('Error setting up request:', error.message);
        setError(`Error: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {!isOpen ? (
        <button
          onClick={toggleChat}
          className="bg-primary text-white p-3 rounded-full shadow-lg hover:bg-primary-dark transition duration-300"
          aria-label="Open chat with Ruthie"
        >
          <MessageSquare size={24} />
        </button>
      ) : (
        <div className="bg-white rounded-lg shadow-xl w-80 h-96 flex flex-col">
          <div className="bg-primary text-white p-4 flex justify-between items-center rounded-t-lg">
            <h3 className="font-semibold">Chat with Ruthie</h3>
            <div className="flex items-center">
              <button onClick={clearChat} className="mr-2" aria-label="Clear chat">
                <Trash2 size={20} />
              </button>
              <button onClick={toggleChat} aria-label="Close chat">
                <X size={20} />
              </button>
            </div>
          </div>
          <div className="flex-grow p-4 overflow-y-auto">
            {messages.map((message, index) => (
              <div key={index} className={`mb-2 ${message.isBot ? 'text-left' : 'text-right'}`}>
                <span className={`inline-block p-2 rounded-lg ${message.isBot ? 'bg-gray-200' : 'bg-primary text-white'}`}>
                  {message.text}
                </span>
              </div>
            ))}
            {isLoading && (
              <div className="text-center">
                <Loader className="animate-spin inline-block" size={24} />
                <p>Ruthie is thinking...</p>
              </div>
            )}
            {error && (
              <div className="text-red-500 text-center mb-2">
                {error}
              </div>
            )}
          </div>
          <div className="p-4 border-t flex">
            <input
              type="text"
              value={inputMessage}
              onChange={handleInputChange}
              placeholder="Type your message to Ruthie..."
              className="flex-grow p-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-primary"
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button
              onClick={handleSendMessage}
              className="bg-primary text-white p-2 rounded-r-md hover:bg-primary-dark transition duration-300"
              aria-label="Send message to Ruthie"
              disabled={isLoading}
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;